import { createRouter, createWebHashHistory } from 'vue-router'



const routes = [
  {
    path: '/',
    name: 'home',
    component:  () => import(/* webpackChunkName: "about" */ '../views/TutorialCollection.vue')
  },
  {
    path: '/tutorial/:id',
    name: 'tutorial',
    component:  () => import(/* webpackChunkName: "about" */ '../views/TutorialPlayer.vue')
  },
  {
    path: '/embed/:id',
    name: 'embed',
    component:  () => import(/* webpackChunkName: "about" */ '../views/TutorialEmbed.vue')
  },
  {
    path: '/tutorials',
    name: 'tutorials',
    component:  () => import(/* webpackChunkName: "about" */ '../views/TutorialCollection.vue')
  },
  {
    path: '/edit',
    name: 'edit',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/TutorialEditor.vue')
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router

<template>
  <router-view/>
</template>

<style lang="scss">

*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

video:focus {
  outline: none;
}

video{
  clip-path: inset(0px 1px);
  background-color: transparent !important
}

nav {
  padding: 20px;
  box-shadow: 0px 0px 10px 3px rgba($color: #000000, $alpha: 0.2);
  margin-bottom: 40px;
  background-color: white;
  img{
    height: 20px;
    position: absolute;
    left: 20px;
  }
  a {
    text-decoration: none;
    font-weight: bold;
    color: #888;
    padding: 0 20px;
    &.router-link-exact-active {
      color: #008CAC;
    }
  }
}
</style>

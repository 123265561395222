import { createApp } from 'vue'
import App from './App.vue'
import VueVideoPlayer from '@videojs-player/vue'
import 'video.js/dist/video-js.css'
import LottieAnimation from "lottie-web-vue";
import store from './store'



import router from './router'



const app = createApp(App).use(router);
app.use(LottieAnimation);
app.use(store);
app.use(VueVideoPlayer)
app.mount("#app");



import { createStore } from 'vuex'
import store from './store.json'
export default createStore({
    state: {
        editor: [],
        counter: -1,
        time: 0,
        stopVid: false,
        record: false,
        autoNext: true,
        animations: store 
    }, 
    mutations: {
        setEditObj(state, obj) {
            state.editor = obj;
        },
        setCounter(state, num) {
            state.counter = num;
        },
        counterPlus(state) {
            state.counter += 1
        },
        counterMinus(state) {
            state.counter -= 1
        },
        changeColor(state, hex) {
            state.editor[0].setup.color = hex;
        },
        changeText(state,c){
            state.editor[0].setup.textColor = c
        },
        changeName(state,c){
            state.editor[0].setup.name = c
        },
        newKey(state, object) {
            var key = { x: object.x, y: object.y, time: object.timecode, duration: 0, still: true, cursor: 'default', text: '' };
            state.editor[0].path.push(key)
            state.editor[0].path.sort((a, b) => a.time - b.time);
            state.time = object.timecode
        },
        sortKeys(state) {
            state.editor[0].path.sort((a, b) => a.time - b.time);
        },
        deleteKey(state, x) {
            if(x >= 0){
                state.editor[0].path.splice(x, 1);
                state.counter = -1
            }
            
        },
    },
    actions: {

    }
})